import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import loadable from '@loadable/component';
import unfurl from '~prismic/unfurl/landing-page';
import SEO from '~layout/SearchEngineOptimization';
import LoadingSpinner from '~components/LoadingSpinner';

const Page = loadable(() => import('~layout/Page'));

const SliceToComponent = loadable(() => import('~components/slices/slices'), {
  fallback: (
    <div style={{ minHeight: '50vh' }}>
      <LoadingSpinner />
    </div>
  )
});

const ROOT_PAGE = 'https://www.alephbeta.org/';

const IndexPage = ({ data }) => {
  const { slices } = unfurl(data);

  return (
    <Page hideMarketingSecondaryHeader>
      <SEO hasDefaultStructuredDataObject pageCanonicalUrl={ROOT_PAGE} />
      <SliceToComponent slices={slices} />
    </Page>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape().isRequired
};

IndexPage.defaultProps = {};

export default withPrismicPreview(IndexPage);

export const query = graphql`
  {
    prismicLandingPage {
      data {
        seo_title
        seo_description
        seo_keywords
        seo_image {
          url
        }
        body2 {
          ... on PrismicLandingPageDataBody2MinimalVideoList {
            slice_type
            id
            primary {
              listtitle {
                text
              }
              video_list_bottom_divider {
                url
              }
            }
            items {
              video_title {
                text
              }
              video_image {
                url
                alt
              }
              content_link {
                url
              }
            }
          }
          ... on PrismicLandingPageDataBody2ShowcaseHero {
            slice_type
            id
            primary {
              title_rich {
                text
                html
              }
              description {
                html
              }
              top_icon {
                url
                alt
              }
              top_icon_subtitle
              hero_image {
                url
                alt
              }
              mobile_friendly_hero_image {
                url
                alt
              }
              cta_2_text
              cta_2_url
              bottom_shape_divider {
                url
              }
            }
          }
          ... on PrismicLandingPageDataBody2MarketingIntroduction {
            slice_type
            id
            primary {
              rich_intro_title {
                text
                html
              }
              intro_video_image {
                alt
                url
              }
              introduction_video1 {
                document {
                  ... on PrismicVideo {
                    data {
                      wistia_url {
                        url
                      }
                    }
                  }
                }
              }
            }
            items {
              author_title {
                text
                html
              }
              author_description {
                text
                html
              }
              author_avatar {
                url
                alt
              }
            }
          }
          ... on PrismicLandingPageDataBody2PreviewSpotlights {
            slice_type
            id
            primary {
              section_identifier
              title
              description {
                html
              }
              bottom_shape_divider {
                url
                alt
              }
            }
            items {
              tab_title
              spotlight_playlist {
                id
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                      }
                      cover_image {
                        url
                        alt
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      videos {
                        video {
                          id
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicLandingPageDataBody2MarketingWaysToLearn {
            slice_type
            id
            primary {
              section_title
              bottom_shape_divider {
                url
                alt
              }
            }
            items {
              title
              description {
                text
              }
              thumbnail {
                alt
                url
              }
            }
          }
          ... on PrismicLandingPageDataBody2ShowcaseLibrary {
            slice_type
            id
            items {
              subtitle {
                text
                html
              }
            }
            primary {
              title
              subtitle {
                html
              }
              mobile_title
              mobile_subtitle {
                html
              }
              library_sections {
                document {
                  ... on PrismicDiscoverLibrary {
                    data {
                      body {
                        ... on PrismicDiscoverLibraryDataBodyLibrarySection {
                          slice_type
                          primary {
                            title
                            see_more_path
                            background_thumbnail {
                              url
                              alt
                            }
                            mobile_cap
                          }
                          items {
                            custom_resource_title
                            custom_path
                            resource {
                              document {
                                ... on PrismicPlaylist {
                                  url
                                  data {
                                    title
                                  }
                                }
                                ... on PrismicPodcastPlaylist {
                                  url
                                  data {
                                    podcast_title {
                                      text
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              bottom_shape_divider {
                url
                alt
              }
            }
          }
          ... on PrismicLandingPageDataBody2VideoOffer {
            slice_type
            id
            primary {
              title_formatted {
                html
                text
              }
              video {
                document {
                  ... on PrismicVideo {
                    id
                    data {
                      wistia_url {
                        url
                      }
                      cover_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              description_formatted {
                html
                text
              }
              background_color
              root_background_color
              primary_text_color
              bold_title_text_color
              cta_text
              cta_url
              cta_text_color
              cta_background_color
              bottom_shape_divider {
                url
                alt
              }
            }
          }
          ... on PrismicLandingPageDataBody2LearningMethods {
            slice_type
            id
            primary {
              title
              text_color
              background_color
              navigation_color
              bottom_shape_divider {
                alt
                url
              }
            }
            items {
              title
              icon {
                alt
                url
              }
              short_explanation {
                text
              }
              animation_file {
                url
              }
              animation_type
              animation_still_frame
              call_to_action_text
              playlist {
                document {
                  __typename
                  ... on PrismicPlaylist {
                    id
                    url
                    data {
                      cover_image {
                        alt
                        url
                      }
                    }
                  }
                }
              }
              second_playlist {
                document {
                  __typename
                  ... on PrismicPlaylist {
                    id
                    url
                    data {
                      cover_image {
                        alt
                        url
                      }
                    }
                  }
                }
              }
              third_playlist {
                document {
                  __typename
                  ... on PrismicPlaylist {
                    id
                    url
                    data {
                      cover_image {
                        alt
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicLandingPageDataBody2Testimonials {
            slice_type
            id
            primary {
              title
              bottom_shape_divider {
                url
                alt
              }
            }
            items {
              testimonial {
                html
              }
              author
              designation
            }
          }
          ... on PrismicLandingPageDataBody2LandingPageFaq {
            slice_type
            id
            primary {
              title
              background_color
              text_color
              item_background_color
              item_text_color
              link_color
              cta_theme
              bottom_shape_divider {
                url
                alt
              }
            }
            items {
              title
              description {
                html
              }
            }
          }
          ... on PrismicLandingPageDataBody2LargeTextCta {
            slice_type
            id
            primary {
              title
              background_color
              text_color
              cta_theme
              bottom_shape_divider {
                url
                alt
              }
            }
          }
          ... on PrismicLandingPageDataBody2FeaturedCourse {
            slice_type
            id
            primary {
              featured_course_title {
                html
              }
              featured_course_description {
                html
              }
              section_background_color
              background_color
              text_color
              top_label
              top_label_background_color
              top_label_text_color
              cta_text
              cta_url
              featured_course_image {
                url
                alt
              }
              bottom_shape_divider {
                url
                alt
              }
            }
          }
          ... on PrismicLandingPageDataBody2AnimationHero {
            slice_type
            id
            primary {
              hero_video {
                url
              }
              hero_video_mp4 {
                url
              }
              hero_mobile_video {
                url
              }
              hero_mobile_video_mp4 {
                url
              }
              video_focus
              background_color
              hero_rich_title {
                html
              }
              rich_text_description {
                text
                html
              }
              title_color
              mobile_description_color
              disable_animation
              animation_end_image {
                alt
                url
              }
              animation_end_background_color
              animation_end_description_color
              animation_description {
                text
                html
              }
            }
          }
          ... on PrismicLandingPageDataBody2MarketingVideoList {
            slice_type
            id
            primary {
              title_video_list {
                text
                html
              }
              see_more_button_text
              see_more_path
              background_color
              text_color
            }
            items {
              playlist {
                id
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          id
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicLandingPageDataBody2FeatureMobileApp {
            id
            slice_type
            primary {
              feature_app_title {
                text
                html
              }
              text_color
              background_color
              ios_app_link {
                url
                target
              }
              ios_cta_text
              android_app_link {
                url
                target
              }
              android_cta_text
              feature_image {
                url
                alt
              }
              feature_image_mobile {
                url
                alt
              }
            }
          }
          ... on PrismicLandingPageDataBody2LargeImageCta {
            id
            slice_type
            primary {
              cta_rich_title {
                text
                html
              }
              cta_theme
              background_image {
                url
                alt
              }
              background_image_mobile {
                url
                alt
              }
              text_color
            }
          }
        }
      }
    }
  }
`;
